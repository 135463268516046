<template>
    <router-link v-if="isButton === false" :to="to" class="document-link">
        <v-icon size="40px" :hovering="false">note-pencil</v-icon>
        <span class="document-link-font" v-html="text" />
    </router-link>
    <div v-else class="button-container">
        <rwm-button outlined width="100%" content-width="100%" @click="routingTo(to)">
            <template slot="prepend-img">
                <v-icon :hovering="false" style="margin-right: 15px">note-pencil</v-icon>
            </template>
            {{text}}
        </rwm-button>
    </div>
</template>

<script>
    import VIcon from "../icons/VIcon";
    import RwmButton from "../RWMButton/RwmButton";
    export default {
         name: "RwmDocumentLink",
        components: {RwmButton, VIcon},
        props: {
            to: [String, Number],
            text: String,
            isButton: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            routingTo(url) {
                this.$router.push({path: url})
            }
        }
    }
</script>

<style scoped lang="scss">
.document-link {
    display: flex;
    max-width: 100%;
    width: max-content;
    align-items: center;
    text-decoration: none;
    margin: 22px 0;
    &-font {
        color: $RWM-red;
        text-decoration: underline;
    }
    & .v-icon {
        color: $RWM-dark;
        margin-right: 18px;
    }
    &:hover .v-icon {
        color: $RWM-red
    }
}
.button-container {
    display: flex;
    margin: 22px 0;
    width: 100%;
    @media screen and (min-width: 961px) {
        min-width: 370px;
        max-width: 550px;
    }
}
</style>
