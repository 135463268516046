<template>
    <div class="documents" id="account">
        <div class="form-container">
            <h1>Документы</h1>
            <div class="form-container__form">
                <div class="not-sended-docs" v-if="!hasANY">
                    <h3>
                        Для продолжения обслуживания Вам необходимо заполнить анкеты и
                        заявления:
                    </h3>
                    <div style="width: 100%">
                        <rwm-document-link
                                v-for="doc in getNEED_TO_FILL"
                                :key="doc.alias"
                                :is-button="Boolean(true)"
                                :to="`/cabinet/documents/forms/${doc.alias}?to`"
                                :text="doc.name"
                        />
                    </div>
                </div>
                <div
                        class="changed-docs"
                        v-else-if="getNEED_UPDATE && getNEED_UPDATE.length > 0"
                >
                    <p class="anketa">
                        {{
                        havePa ?
                        "Вы изменили анкетные данные в своем профиле. Вам необходимо заполнить и отправить заявление об изменении данных анкеты зарегистрированного физического лица и актуализировать пакет документов:"
                        : "Вы изменили анкетные данные в своем профиле. Вам необходимо актуализировать пакет документов:"
                        }}
                    </p>
                    <div style="width: 100%">
                        <rwm-document-link
                                v-for="doc in getNEED_UPDATE"
                                :key="doc.id"
                                :is-button="Boolean(true)"
                                :to="`/cabinet/documents/forms/${doc.alias}?to`"
                                :text="doc.name"
                        />
                    </div>
                </div>
                <div
                        class="not-sended-docs"
                        v-else-if="getALL_DRAFTS && getALL_DRAFTS.length > 0"
                >
                    <h3>
                        У Вас есть неотправленные документы. Не забудьте заполнить и
                        отправить
                    </h3>
                    <template v-for="doc in getALL_DRAFTS">
                        <div style="width: 100%" :key="doc.alias">
                            <rwm-document-link
                                    :is-button="Boolean(true)"
                                    :to="`/cabinet/documents/forms/${doc.alias}?to`"
                                    :text="doc.name"
                            />
                        </div>
                    </template>
                </div>
                <div v-else-if="hasEMPTY && haveACTUAL_DOCUMENTS">
                    <h3>
                        Для продолжения обслуживания Вам необходимо заполнить анкеты и
                        заявления:
                    </h3>
                    <div style="width: 100%">
                        <rwm-document-link
                                v-for="doc in getNEED_TO_FILL"
                                :key="doc.alias"
                                :is-button="Boolean(true)"
                                :to="`/cabinet/documents/forms/${doc.alias}?to`"
                                :text="doc.name"
                        />
                    </div>
                </div>
                <div v-else-if="hasEMPTY && !haveACTUAL_DOCUMENTS">
                    <p class="anketa">У вас нет отправленных документов.</p>
                </div>
                <div v-else-if="
                  (!getALL_DRAFTS || getALL_DRAFTS.length === 0) &&
                  (!getNEED_UPDATE || getNEED_UPDATE.length === 0)
                  ">
                    <p class="anketa">Ваши документы в порядке.</p>
                </div>
<!--              <p v-if="isChecked && !haveACTUAL_KI" class="anketa bold">-->
<!--                Заявление о признании физического лица квалифицированным инвестором Вы можете подать на-->
<!--                бумажном носителе в офис ЗАО УК «РВМ Капитал», расположенный по адресу: 105064, Москва, ул.-->
<!--                Земляной Вал, д. 9. эт/пом/ком 8/I/1,3-7.-->
<!--              </p>-->
                <div class="row" style="margin: 46px 0">
                    <rwm-checkbox
                            :checked="isChecked"
                            :readonly="haveACTUAL_KI"
                            label="Являюсь квалифицированным инвестором"
                            @change="onChange"
                    />
                </div>
                <transition name="slide" mode="in-out">
                    <div v-if="isChecked && !haveACTUAL_KI">
                        <p class="anketa">
                            В соответствии с Федеральным законом от 22.04.1996 № 39-ФЗ «О рынке ценных бумаг» и
                            Указанием Банка России от 29.04.2015 № 3629-У «О признании лиц квалифицированными
                            инвесторами и порядке ведения реестра лиц, признанных квалифицированными инвесторами»
                            квалицированный инвестор, физическое лицо,
                            <b>должен соответствовать одному из перечисленных требований:</b>
                        </p>
                        <ul>
                            <li>
                                <p class="anketa">
                                    владеть активами (ценные бумаги или денежные средства) на сумму <b>не менее 6 млн руб.</b>;
                                </p>
                            </li>
                            <li>
                                <p class="anketa">
                                    <b>обладать опытом работы в организации</b>, которая совершала сделки с
                                    ценными бумагами не менее 2-х лет, если такая организация является квалифицированным
                                    инвестором, или не менее трех лет в иных случаях;
                                </p>
                            </li>
                            <li>
                                <p class="anketa">
                                    <b>обладать опытом работы в должности</b>, при назначении (избрании) на которую в
                                    соответствии с федеральными законами требовалось согласование Банка России
                                </p>
                            </li>
                            <li>
                                <p class="anketa">
                                    <b>совершать сделки с ценными бумагами и (или) заключать договоры</b>, являющиеся
                                    производными финансовыми инструментами, со следующей регулярностью: не менее одной
                                    сделки в месяц и в среднем не менее десяти сделок в квартал в течение четырех
                                    кварталов (то есть года), объемом не менее 6 млн рублей;
                                </p>
                            </li>
                            <li>
                                <p class="anketa">
                                    <b>наличие высшего экономического образования</b> или любого из аттестатов:
                                    свидетельство о квалификации для работы на финансовом рынке, сертификат Chartered
                                    Financial Analyst (CFA), сертификат Certified International Investment Analyst
                                    (CIIA), сертификат Financial Risk Manager (FRM). При этом в первом случае вуз на
                                    момент выдачи диплома должен обладать правом аттестации граждан в сфере
                                    профессиональной деятельности на рынке ценных бумаг.
                                </p>
                            </li>
                        </ul>
                      <rwm-button
                          v-if="!haveACTUAL_KI"
                          class="qualified-investor"
                          width="100%"
                          @click="$router.push('/cabinet/documents/forms/ki?to')"
                      >Заявление на признание КИ
                      </rwm-button>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
    import RwmDocumentLink from "../../RWMDocumentLink/RwmDocumentLink";
    import RwmCheckbox from "../../RWMCheckbox/RwmCheckbox";
    import {mapActions, mapGetters} from "vuex";
    import RwmButton from "@/components/RWMButton/RwmButton";

    export default {
        name: "Documents",
        components: {RwmButton, RwmCheckbox, RwmDocumentLink},
        props: {
            ki: Boolean,
            havePa: Boolean
        },
        computed: {
            ...mapGetters("DocumentsModule", [
                "getALL_DRAFTS",
                "getNEED_UPDATE",
                "haveACTUAL_KI",
                "hasEMPTY",
                "haveACTUAL_DOCUMENTS",
                "getNEED_TO_FILL",
                "hasANY",
            ]),
            isChecked: {
                get() {
                    return this.ki;
                },
                set(val) {
                    this.$emit("changeKi", val);
                },
            },
        },
        methods: {
            ...mapActions("AuthModule", ["GET_USER"]),
            ...mapActions("DocumentsModule", [
                "GET_ALL_DOCUMENTS",
                "HAS_EMPTY",
                "HAS_ANY",
            ]),
            onChange(val) {
                this.isChecked = val;
                this.HAS_EMPTY();
            },
            nextDocument() {
                document.getElementsByClassName('cabinet-wrapper').item(0).scroll(-100,0)
                if (typeof this.$route.query.next !== "undefined"){
                    if(this.getNEED_UPDATE != null && this.getNEED_UPDATE.length > 0){
                        if (this.getNEED_UPDATE.find(item => item.alias == "pod-ft") != null)
                            this.$router.push('/cabinet/documents/forms/pod-ft?to');
                        else if (this.getNEED_UPDATE.find(item => item.alias == "fatca") != null)
                            this.$router.push('/cabinet/documents/forms/fatca?to');
                        else if (this.getNEED_UPDATE.find(item => item.alias == "change-personal-data") != null)
                            this.$router.push('/cabinet/documents/forms/change-personal-data?to');
                    } else this.$router.push('/cabinet/documents');
                }
            },
        },
        watch: {},
        async mounted() {
            this.GET_USER();
            this.HAS_ANY();
            this.GET_ALL_DOCUMENTS({documentsListType: "actuals"});
            this.GET_ALL_DOCUMENTS({documentsListType: "drafts"});
            this.HAS_EMPTY();
            await this.GET_ALL_DOCUMENTS({documentsListType: "updates"});
            this.nextDocument();
        },
    };
</script>

<style scoped lang="scss">
    .qualified-investor {
        max-width: 464px;
    }

    .not-sended-docs {
        max-width: 100%;
        display: flex;
        -moz-column-gap: 32px;
        column-gap: 32px;
        row-gap: 32px;
        flex-wrap: wrap;
    }

    .changed-docs {
        max-width: 100%;
    }

    li {
        margin-bottom: 10px;
    }
</style>
