<template>
    <div class="client-profile">
        <documents
                :key="'docs' + docChanged + userChanged"
                v-if="$router.currentRoute.name==='ClientDocuments'"
                :ki="getUSER.ki"
                :have-pa="IS_VISIBE_CHANGES"
                @changeKi="changeKi"
        />
        <router-view></router-view>
    </div>
</template>

<script>
    import Documents from "../../components/pages/cabinet/Docments";
    import {mapActions, mapGetters} from "vuex"

    export default {
        name: "ClientDocuments",
        components: {Documents},
        data() {
          return {
              documentsKey: 0,
          }
        },
        computed: {
            ...mapGetters("AuthModule", ["getUSER"]),
            ...mapGetters("Sockets", ["IS_VISIBE_CHANGES"]),
            ...mapGetters("AutoUpdateModule", ["docChanged", "userChanged"])
        },
        methods: {
            ...mapActions("AuthModule", ["SET_KI"]),
            changeKi(val) {
                this.SET_KI({ki: val})
            }
        },
    }
</script>

<style scoped lang="scss">
</style>